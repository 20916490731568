.arrows {
    width: 45px;
    height: 70px;
    position: relative;
    left: 50%;
    margin-left: -15px;
    margin-top: 20px;
    bottom: 0px;
}

.arrows path {
    stroke: rgba(33, 160, 189, 0.9);
    fill: transparent;
    stroke-width: 4px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
    0% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes arrow
    {
    0% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

.arrows path.a1 {
    animation-delay: -1s;
    -webkit-animation-delay: -1s;
}

.arrows path.a2 {
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s;
}

.arrows path.a3 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
}